import React, { useState } from "react";
import { Button, CssBaseline, TextField, Paper, Grid, Typography, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import loginBackground from "../assets/images/loginBackground.png";
import logo from '../assets/images/logo_rojo_transparente.png';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#50e3c2',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#50e3c2',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 15,
    '& fieldset': {
      borderColor: '#50e3c2',
    },
    '&:hover fieldset': {
      borderColor: '#50e3c2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#50e3c2',
    },
  },
  marginBottom: '16px',
});

const Root = styled(Grid)({
  height: "100vh",
});

const Image = styled(Grid)({
  backgroundImage: `url(${loginBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundColor: (props) => props.theme.palette.type === "dark" ? props.theme.palette.grey[900] : props.theme.palette.grey[50],
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const PaperContainer = styled(Paper)({
  margin: '64px 32px',
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
});

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const TextLogin = styled(Typography)({
  marginTop: '8px',
  marginBottom: '16px',
  height: 30,
  fontSize: 22,
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.36,
  letterSpacing: 1.38,
  color: "#4a4a4a",
});

const Form = styled('form')({
  width: "100%",
});

const SubmitButton = styled(Button)({
  textTransform: "none",
  borderRadius: 15,
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.36,
  letterSpacing: 1.38,
  margin: "10px auto",
  padding: "10px",
  backgroundColor: "#e46450",
  "&:hover": {
    backgroundColor: "red",
  },
  width: '80%',
  maxWidth: '300px',
});

const VolverText = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#006BC8',
  marginTop: '10px',
});

export default function ResetPass(props) {
//   const firebase = useFirebase();
  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [errors, setErrors] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [resetPassError, setResetPassError] = useState(false);

  // const handleClickSnack = () => {
  //   setOpenSnack(true);
  // };
  
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Root container component="main">
      <CssBaseline />
      <Image item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={PaperContainer} elevation={6} square>
        <div>
          <Logo src={logo} alt="Logo Mayah Rojo" />
          <TextLogin component="h1" variant="h5">
            ¿Te olvidaste tu contraseña?
          </TextLogin>
          {/* <Form onSubmit={handleForgotPassword}> */}
          <Form>
            <Typography variant="subtitle2" marginBottom="16px">
              Escribí tu email y recibí instrucciones para recuperarla.
            </Typography>
            <CssTextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setErrors("");
                setMail(e.target.value);
              }}
            />
            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
            >
              Recuperar contraseña
            </SubmitButton>
            <VolverText onClick={() => { navigate('/login'); }}>
              Volver
            </VolverText>
          </Form>
          <Snackbar
            open={openSnack}
            autoHideDuration={6000}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            {/* {resetPassError ?
              <Alert onClose={handleCloseSnack} severity="error">
                Correo inexistente
              </Alert>
              : <Alert onClose={handleCloseSnack} severity="warning">
                Verifica tu correo para continuar
              </Alert>
            } */}
          </Snackbar>
        </div>
      </Grid>
    </Root>
  );
}
