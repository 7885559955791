import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import Webcam from 'react-webcam';

const ImageUpload = ({ setProductImage }) => {
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [hasCamera, setHasCamera] = useState(false);
  const webcamRef = useRef(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(prevFiles => [
        ...prevFiles,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    // Make sure to revoke the data URIs to avoid memory leaks
    setProductImage(files);
  }, [files, setProductImage]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCameraOpen = () => {
    setCameraOpen(true);
  };

  const handleCameraClose = () => {
    setCameraOpen(false);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = new File([imageSrc], 'camera-photo.jpg', { type: 'image/jpeg' });
    file.preview = imageSrc;
    setFiles(prevFiles => [...prevFiles, file]);
    handleCameraClose();
  }, []);

  useEffect(() => {
    try {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
        setHasCamera(videoInputDevices.length > 0);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        Agregar imagen
      </Button>
      {hasCamera && (
        <Button variant="contained" onClick={handleCameraOpen}>
          Usar cámara
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Subir Imágenes</DialogTitle>
        <DialogContent>
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed #ccc',
              padding: 2,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
            }}
          >
            <input {...getInputProps()} />
            <Typography variant="h6">
              {isDragActive ? 'Suelta los archivos aquí...' : 'Arrastra y suelta algunas imágenes aquí, o haz clic para seleccionar archivos'}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 2 }}>
              {files.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    width: 100,
                    height: 100,
                    margin: 1,
                    backgroundImage: `url(${file.preview})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}
                    onClick={removeFile(file)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cameraOpen} onClose={handleCameraClose} fullWidth maxWidth="sm">
        <DialogTitle>Tomar una foto</DialogTitle>
        <DialogContent>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              facingMode: 'environment' // Intenta usar la cámara trasera si está disponible
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={capture} color="primary">
            Capturar
          </Button>
          <Button onClick={handleCameraClose} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageUpload;
