import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsSuccess } from "../store/notificationSlice";
import PropTypes from "prop-types";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Divider,
  Tooltip,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScrollBar from "./ScrollBar";
import { fToNow, fToNowEs } from "../utils/format-time";
import { markAllNotificationsAsRead } from "../api/notifications";

export const NotificationsPopover = () => {
  const dispatch = useDispatch();
  const { notifications, loading, error } = useSelector(
    (state) => state.notifications
  );
  const totalUnRead = notifications.filter((item) => item.read === null).length;
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);
  const handleMarkAllAsRead = async () => {
    // setNotifications(notifications.map((notification) => ({ ...notification, isUnRead: false })));
    const response = await markAllNotificationsAsRead();
    if (response.status === 200) {
      dispatch(fetchNotificationsSuccess(notifications.map((notification) => ({ ...notification, read: true }))));
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{ sx: { mt: 1.5, ml: 0.75, width: 360 } }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Typography variant="subtitle1">Notificaciones</Typography>
          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton onClick={handleMarkAllAsRead}>
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <ScrollBar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Nuevas
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Box sx={{ p: 1 }}>
            <Button fullWidth disableRipple onClick={handleMarkAllAsRead}>
              Marcar todo como leído
            </Button>
          </Box>
        </ScrollBar>
      </Popover>
    </>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    read: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function parseSendDate(send_date) {
  
  // 2024-05-30T19:14:33.000Z this is the format of send_date, transform it to 2024-05-30 19:14:33
  let date = send_date.split("T")[0];
  // Crear un objeto Date
  let parsedDate = new Date(date);
  
  return fToNowEs(parsedDate);

}

function NotificationItem({ notification }) {
  const { avatar, title, body, read, send_date } = renderContent(notification);
  return (
    <ListItemButton sx={{ py: 1.5, px: 2.5, mt: '1px', ...(!notification.read && { bgcolor: 'action.selected' }) }}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={<Typography variant="caption" sx={{ mt: 0.5, display: 'flex', alignItems: 'center', color: 'text.disabled' }}>
          <AccessTimeIcon sx={{ mr: 0.5, width: 16, height:  16 }} />{parseSendDate(notification.send_date)}
        </Typography>}
      />
    </ListItemButton>
  );
}

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="p"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp;{notification.body}
      </Typography>
    </Typography>
  );
  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
