import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { register } from './serviceWorkerRegistration';

register(); // Llama a esta función para registrar tu service worker.
// Crear un tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#E46450', // color rojo Mayah
    },
    secondary: {
      main: '#19857b', // color verde por ejemplo
    },
    error: {
      main: '#ff0000', // color rojo por ejemplo
    },
    background: {
      default: '#f4f6f8', // un gris muy claro para el fondo
      paper: '#ffffff', // fondo blanco para elementos que se asemejan a papel
    },
    text: {
      primary: '#333333', // texto principalmente oscuro
      secondary: '#555555', // texto menos prominente
    }
  },
  typography: {
    fontFamily: [
      'Lato', // Puedes colocar tu fuente preferida aquí
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    // Puedes personalizar más detalles tipográficos si lo deseas
  }
});

// src/index.js
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
