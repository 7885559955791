import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, TextField, Box, Typography, Card, CardContent, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Tesseract from 'tesseract.js';
import { createProduct } from '../api/products';
import { CustomizedAlert } from '../components/CustomizedAlert';
import { styled } from '@mui/system';
import ImageUpload from '../components/ImageUpload';

const ScanContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '20px',
});

const ScanCard = styled(Card)({
  maxWidth: '500px',
  width: '100%',
  padding: '20px',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
});

const ScanTitle = styled(Typography)({
  marginBottom: '16px',
  fontSize: '24px',
  fontWeight: 'bold',
});

const ScanButton = styled(Button)({
  marginBottom: '16px',
});

const ProductImage = styled('img')({
  width: '100%',
  marginTop: '10px',
  borderRadius: '8px',
});

const ScanFormControlLabel = styled(FormControlLabel)({
  marginTop: '16px',
});

const ScanTextField = styled(TextField)({
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#f7f7f7',
  borderRadius: '8px',
});

const ScanActions = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '16px',
});

export const ProductScan = () => {
  const user = useSelector(state => state.auth.user);
  const [productImage, setProductImage] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [isAllFieldsLoaded, setIsAllFieldsLoaded] = useState(false);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productBrand, setProductBrand] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productSKU, setProductSKU] = useState('');

  const [manualInput, setManualInput] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0] || event.target.value;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const processedImage = preprocessImage(image);
          processImage(processedImage);
          setProductImage(processedImage);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const preprocessImage = (image) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const avg = 0.3 * data[i] + 0.59 * data[i + 1] + 0.11 * data[i + 2];
      data[i] = avg;
      data[i + 1] = avg;
      data[i + 2] = avg;
    }
    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
  };

  const processImage = (image) => {
    Tesseract.recognize(
      image,
      'eng',
      {
        logger: m => console.log(m), // Opcional: registra el progreso del OCR
      }
    ).then(({ data: { text } }) => {
      const foundDate = extractDate(text);
      console.log("data encontrada: ", text);
      if (foundDate) {
        // for any 'foundDate' format always transform to dd-mm-yyyy
        const date = foundDate.replaceAll('/', '-');
        setExpiryDate(foundDate);
        setIsAllFieldsLoaded(true);
      }
    });
  };

  const extractDate = (text) => {
    const patterns = [/(\d{2}[\/.-]\d{2}[\/.-]\d{2,4})|(\d{2}[\/.-][A-Z]{3}[\/.-]\d{2,4})|(\d{2}[A-Z]{3}\d{2})|(\d{2}[A-Z]{3})|(\d{2}[A-Z]{3}[\/.-]\d{2,4})|(\d{2}[\/.-]\d{2,4})|(\d{2}\d{2}\d{2})|(\d{2}\D+\d{4})/i];

    for (let pattern of patterns) {
      const match = text.match(pattern);
      if (match) {
        return match[0]; // Devuelve la primera fecha válida encontrada
      }
    }
    return null; // Si no se encuentra ninguna fecha
  };

  const handleCameraInput = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
      const video = document.createElement('video');
      video.srcObject = stream;
      video.play();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      processImage(image);
      setProductImage(image);
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const clearData = () => {
    setProductImage('');
    setExpiryDate('');
    setQuantity('');
    setProductName('');
    setProductDescription('');
    setProductBrand('');
    setProductCategory('');
    setProductSKU('');
    setIsAllFieldsLoaded(false);
  };

  const handleShowManualInput = () => {
    setManualInput(!manualInput);
    setIsAllFieldsLoaded(true);
  };

  const handleSaveProduct = async () => {
    try {
      const formData = new FormData();
      formData.append('name', productName);
      formData.append('description', productDescription);
      formData.append('category', productCategory);
      formData.append('brand', productBrand);
      formData.append('bar_code', productSKU);
      formData.append('expiration_date', expiryDate);
      formData.append('stock', quantity);
      formData.append('stores_id', user.storeId); // Assuming 'storeId' is stored in user object
      formData.append('image', productImage[0]);
      console.log('image: ', productImage[0]);


      let product = await createProduct(formData);
      console.log(product);
      setIsAlertOpen(true);
      setAlertMessage("Producto guardado exitosamente");
      setAlertSeverity("success");
      clearData(); // Clear form on success
    } catch (error) {
      setIsAlertOpen(true);
      setAlertMessage("Error al guardar producto, intente más tarde");
      setAlertSeverity("error");
    }
  };

  useEffect(() => {
    console.log("expiryDate: ", expiryDate);
  }, [expiryDate]);

  return (
    <ScanContainer>
      <ScanTitle variant="h6">Gestión de Productos</ScanTitle>
      <ScanCard>
        <CardContent>
          {/* <Tooltip title="Usar la cámara para capturar la imagen del producto">
            <ScanButton variant="contained" onClick={handleCameraInput} startIcon={<CameraAltIcon />}>
              Usar Cámara
            </ScanButton>
          </Tooltip>
          <Tooltip title="Cargar imagen desde los archivos">
            <ScanButton variant="contained" component="label" startIcon={<PhotoLibraryIcon />}>
              Cargar Imagen
              <input type="file" hidden accept="image/*" onChange={handleImageUpload} />
            </ScanButton>
          </Tooltip> */}
          {/* {productImage && <ProductImage src={productImage} alt="Product" />}
          <ScanFormControlLabel
            control={<Checkbox checked={manualInput} onChange={handleShowManualInput} />}
            label="Carga manual de producto"
          /> */}
          {manualInput && (
            <>
              <ScanTextField
                label="Nombre del Producto"
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <ScanTextField
                label="Descripción del Producto"
                type="text"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
              />
              <ScanTextField
                label="Marca del Producto"
                type="text"
                value={productBrand}
                onChange={(e) => setProductBrand(e.target.value)}
              />
              <ScanTextField
                label="Categoría del Producto"
                type="text"
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
              />
              <ScanTextField
                label="SKU"
                type="text"
                value={productSKU}
                onChange={(e) => setProductSKU(e.target.value)}
              />
            </>
          )}
          <ScanTextField
            label="Fecha de Vencimiento"
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ScanTextField
            label="Cantidad"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
            <ImageUpload setProductImage={setProductImage} />
          <ScanActions>
            <Button variant="outlined" sx={{ mr: 1 }} onClick={clearData}>Cancelar</Button>
            <Button
              variant="contained"
              // disabled={!isAllFieldsLoaded}
              onClick={handleSaveProduct}
            >
              Agregar
            </Button>
          </ScanActions>
        </CardContent>
      </ScanCard>
      <CustomizedAlert message={alertMessage} severity={alertSeverity} openHandler={isAlertOpen} />
    </ScanContainer>
  );
};
