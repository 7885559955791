import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Chip,
  IconButton,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/system";
import no_image_products from "../../assets/images/no_image_products.png";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { setProductComertialAction, updateProductStock } from "../../api/products";
import { CustomizedAlert } from "../CustomizedAlert";

const InventoryCard = styled(Card)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "16px",
  padding: "16px",
  backgroundColor: "#fff",
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  borderRadius: "8px",
  "@media (min-width:960px)": {
    maxWidth: "320px",
  },
});

const CardBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

const CardContentStyled = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexWrap: "wrap",
});

const ChipStyled = styled(Chip)({
  marginBottom: "8px",
});

const TextStyled = styled(Typography)({
  textTransform: "capitalize",
  marginBottom: "8px",
  maxWidth: '140px',
  overflowWrap: 'break-word'
});

export const InventoryStock = ({ productData, actionCallBack }) => {
  const baseURL = process.env.REACT_APP_IMAGES_URL;
  const {
    image,
    name,
    brand,
    expirationDate,
    daysToExpiration,
    quantity,
    latest_expirations,
    // eslint-disable-next-line
    editable,
    creation_date,
    comercial_action
  } = productData;
  
  const [enableEdit, setEnableEdit] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newQuantity, setNewQuantity] = useState(latest_expirations && latest_expirations[0].stock);
  const [selectedExpiration, setSelectedExpiration] = useState(latest_expirations && latest_expirations[0].expiration_date);
  const [comercialAction, setComercialAction] = useState(comercial_action);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const chipDaysColors = (days) => {
    if (days < 0) return "default";
    else if (days <= 10) return "error";
    else if (days <= 20) return "warning";
    else if (days <= 30) return "info";
    else return "success";
  };

  const expirationText = (days) => {
    if (days < 0) return `Vencido hace ${days * -1} día${days === -1 ? '' : 's'}`;
    else if (days === 0) return "Vence hoy";
    else return `Vence en ${days} día${days === 1 ? '' : 's'}`;
  };

  const handleUpdateStock = async () => {
    try {
      console.log(latest_expirations);
      const response = await updateProductStock(
        productData.id,
        newQuantity,
        selectedExpiration
      );
      console.log("response: ", response);
      setEnableEdit(false);
      setEditing(false);
      // Actualiza la cantidad en el producto original después de la actualización
      if (actionCallBack) {
        actionCallBack(productData.id, newQuantity);
      }
      setIsAlertOpen(true);
      setAlertMessage("Producto guardado exitosamente");
      setAlertSeverity("success");
    } catch (error) {
      setIsAlertOpen(true);
      setAlertMessage("Error al actualizar producto, intente más tarde");
      setAlertSeverity("error");
      console.log("response error: ", error);
    }
  };

  const handleChangeComercialAction = async (event) => {
    const updatedComAction = event.target.checked;
    try {
      await setProductComertialAction(productData.products_id, productData.expiration_date, updatedComAction);
      setComercialAction(updatedComAction);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleChangeImage = () => {
    if (!image) {
      console.log('no tienen imagen');
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <InventoryCard>
          <CardBox>
            <CardContentStyled>
              {daysToExpiration || daysToExpiration === 0 ? (
                <ChipStyled
                  label={expirationText(daysToExpiration)}
                  color={chipDaysColors(daysToExpiration)}
                  variant="filled"
                />
              ) : null}
              <TextStyled
                component="div"
                variant="subtitle1"
                textAlign="left"
                fontSize="16px"
                fontWeight="500"
              >
                <b>{name}</b>
              </TextStyled>
              {brand && (
                <TextStyled
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  textAlign="left"
                  fontSize="14px"
                >
                  {brand}
                </TextStyled>
              )}
              {expirationDate && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  textAlign="left"
                  fontSize="14px"
                >
                  Vencimiento: {expirationDate}
                </Typography>
              )}
            </CardContentStyled>
              <Typography
              variant="subtitle1"
              color="text.secondary"
              component="p">
                Detalle del producto
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                textAlign="left"
              >
                Cantidad:
                {!enableEdit ? (
                  quantity
                ) : (
                  <>
                    {latest_expirations.length > 1 && (
                      <FormControl
                        variant="outlined"
                        size="small"
                        sx={{ ml: 1, minWidth: 120 }}
                      >
                        <InputLabel id="select-expiration-label">
                          Fecha
                        </InputLabel>
                        <Select
                          labelId="select-expiration-label"
                          id="select-expiration"
                          value={selectedExpiration}
                          onChange={(e) => {
                            setSelectedExpiration(e.target.value);
                            // find the selected expiration date in the latest expirations an asign her stock in setNewQuantity
                            const selectedExpirationDate =
                              latest_expirations.find(
                                (exp) => exp.expiration_date === e.target.value
                              );
                            setNewQuantity(selectedExpirationDate.stock);
                          }}
                          label="Fecha"
                        >
                          {latest_expirations.map((exp) => (
                            <MenuItem
                              key={exp.expiration_date}
                              value={exp.expiration_date}
                            >
                              {exp.expiration_date}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={newQuantity}
                      onChange={(e) => {
                        setEditing(true);
                        setNewQuantity(e.target.value);
                        console.log(e.target.value);
                      }}
                      aria-label="Cantidad"
                      inputProps={{ "aria-label": "Cantidad" }}
                      sx={{ ml: 1, width: "60px" }}
                    />
                  </>
                )}
                {editing && (
                  <IconButton
                    variant="contained"
                    aria-label="guardar cantidad"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={handleUpdateStock}
                  >
                    <SaveIcon fontSize="small" />
                    Guardar
                  </IconButton>
                )}
                {!editing && enableEdit && (
                  <IconButton
                    variant="contained"
                    aria-label="cancelar edición"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setEnableEdit(false);
                      setEditing(false);
                      setNewQuantity(quantity); // Resetear la cantidad al cancelar
                      setSelectedExpiration(
                        latest_expirations[0].expiration_date
                      ); // Resetear la selección al cancelar
                    }}
                  >
                    Cancelar
                  </IconButton>
                )}
                {!editing && !enableEdit && (daysToExpiration === undefined) && (
                  <IconButton
                    variant="contained"
                    aria-label="editar cantidad"
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setEnableEdit(true);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Typography>
              { creation_date &&
                <Typography
                variant="subtitle1"
                color="text.secondary"
                component="p">
                  Creacion: {creation_date}
                </Typography>
              }
              { (daysToExpiration || daysToExpiration === 0) &&
                <>

                  <FormControlLabel
                    style={{fontSize: 12}}
                    control={
                      <Checkbox
                        disabled={comercialAction}
                        checked={comercialAction}
                        onChange={handleChangeComercialAction}
                      />
                    }
                    label={ "Acción comercial" + (comercialAction ? " solicitada":"")}
                  />
                </>
              }
          </CardBox>
          <CardMedia
            component="img"
            sx={{ width: 90, height: "auto", borderRadius: "8px" }}
            image={image ? baseURL + image : no_image_products}
            onClick={handleChangeImage}
            alt="Imagen del producto"
          />
        </InventoryCard>
      </Grid>
      <CustomizedAlert
        message={alertMessage}
        severity={alertSeverity}
        openHandler={isAlertOpen}
      />
    </>
  );
};
