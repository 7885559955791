import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Box, TextField, Button, Select, MenuItem } from '@mui/material';
import { SkeletonProducts } from './cards/SkeletonProducts';
import SortIcon from '@mui/icons-material/Sort';
import SearchIcon from '@mui/icons-material/Search';

import { InventoryStock } from './cards/InventoryStock';
import { handleSearchProductStock, listProducts } from '../api/products';
import { CustomizedAlert } from '../components/CustomizedAlert';

export const Stock = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsBackUp, setProductsBackUp] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const products = await listProducts("stock");

      products.map(product => {
        // product has an subarray with stock, we need to ierate this subarray and add all stocks quantity in quantity variable
        let quantity = 0;
        product.latest_expirations.forEach(expiration => {
          quantity += expiration.stock;
        });
        product.quantity = quantity;
        return product;
      });
      
      setProducts(products);
      setProductsBackUp(products);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setProducts([]);
      setIsLoading(false);
    }
    
  }

  useEffect(() => {
    fetchProducts();
  }, [])

  const handleSearchProduct = async (searchtext) => {
    try {
      let findProducts = await handleSearchProductStock(searchtext, 'stock');
      findProducts.map(product => {
        // product has an subarray with stock, we need to ierate this subarray and add all stocks quantity in quantity variable
        let quantity = 0;
        product.latest_expirations.forEach(expiration => {
          quantity += expiration.stock;
        });
        product.quantity = quantity;
        return product;
      });
      setProducts(findProducts);
    } catch (error) {
      setProducts(productsBackUp);
      setIsAlertOpen(true);
      setAlertMessage("No se encontraron productos con esa búsqueda");
      setAlertSeverity("error");
    }
  }

  const handleFilterProducts = async (searchtext) => {
    if (searchtext === "-1") {
      setProducts(productsBackUp);
      return;
    }
    try {
      console.log("Pedrito")
      return
      // let findProducts = await handlefilterProductsExpirations(
      //   searchtext,
      //   "expiration_date"
      // );
      // findProducts.map((product) => {
      //   let quantity = 0;
      //   let daysToExpiration = 0;
      //   product.latest_expirations.forEach((expiration) => {
      //     quantity += expiration.stock;
      //     daysToExpiration = getDaysToExpiration(expiration.expiration_date);
      //   });
      //   product.daysToExpiration = daysToExpiration;
      //   product.quantity = quantity;
      //   return product;
      // });
      // setProducts(findProducts);
    } catch (error) {
      setProducts(productsBackUp);
      setIsAlertOpen(true);
      setAlertMessage("No se encontraron productos con ese filtro");
      setAlertSeverity("error");
      console.log(error);
    }
  };

  const showPopUp = (productID, newQuantity) => {
    // in products where product.id === productID, update product.quantity to newQuantity
    // then, setProducts(products)
    products.map(product => {
      if (product.id === productID) {
        product.quantity = newQuantity;
      }
      return product;
    });
    setProducts(products);
  }

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <Box padding={2}>      
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!filterOpen ? (
          <>
            <TextField
              fullWidth
              label="Buscar productos o categorías"
              id="fullWidth"
              sx={{
                backgroundColor: "#FFF",
                flex: 7,
                transition: 'flex 0.3s ease',
                marginRight: 2
              }}
              onChange={(e) => handleSearchProduct(e.target.value)}

            />
            <Button onClick={toggleFilter} variant="contained">
              <SortIcon />
            </Button>
          </>
        ) : (
            <Button onClick={toggleFilter} variant="contained">
              <SearchIcon />
            </Button>
        )}
        <Box
          display={filterOpen ? 'block' : 'none'}
          sx={{
            flex: filterOpen ? 3 : 0,
            transition: 'flex 0.3s ease',
            marginLeft: 2
          }}
        >
          <TextField
            fullWidth
            type='date'
          />

        </Box>
      </Box>
      { isLoading ?
          <>
            <SkeletonProducts/>
            <SkeletonProducts/>
            <SkeletonProducts/>
          </>
        : <>
          { products && products.length > 0 && products.map(product => (
              <InventoryStock
                key={product.id}
                productData={product}
                actionCallBack={showPopUp}
              />
            ))
          }
          { products && products.length === 0 &&
            <>
              <Typography sx={{textAlign: 'center', mt: 4}}>Aún no cargaste productos</Typography> 
              <Button
                variant='contained'
                fullWidth
                sx={{mt: 4}}
                onClick={()=>{navigate('/product-scan')}}
              >Cargar productos</Button>
            </>
          }
        </>
      }
      
      <CustomizedAlert message={alertMessage} severity={alertSeverity} openHandler={isAlertOpen}/>
    </Box>
  );
};