import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export const BottomNavigationComponent = () => {
  const navigate = useNavigate();

  // Función para manejar el cambio de sección
  const handleChange = (event, newValue) => {
    let newPath = '/';
    switch (newValue) {
      case 0:
        newPath = '/';
        break;
      case 1:
        newPath = '/inventory/0';
        break;
      case 2:
        newPath = '/product-scan';
        break;
      default:
        newPath = '/';
    }
    navigate(newPath);
  };

  return (
    <BottomNavigation
      onChange={handleChange}
      showLabels
      style={{ position: 'fixed', bottom: 0, width: '100%', boxShadow: '#000000ad 0px 1px 5px 0px' }}
    >
      <BottomNavigationAction label="Inicio" icon={<HomeIcon />} />
      <BottomNavigationAction label="Inventario" icon={<StorageIcon />} />
      <BottomNavigationAction label="Escanear" icon={<CameraAltIcon />} />
    </BottomNavigation>
  );
};
