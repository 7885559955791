import axios from 'axios';

// const baseURL = process.env.API_BASE_URL;
const baseURL = process.env.REACT_APP_API_BASE_URL + '/notifications';
const REACT_APP_VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY


// notifications.js
if ('Notification' in window && Notification.permission !== 'granted') {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Notification permission denied.');
    }
  });
}

export const subscribeToNotifications = async (userId) => {
  let token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };

  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: REACT_APP_VAPID_PUBLIC_KEY
      });
      
      // Convertir BufferSources a Base64
      const keyAuth = btoa(String.fromCharCode(...new Uint8Array(subscription.getKey('auth'))));
      const keyP256dh = btoa(String.fromCharCode(...new Uint8Array(subscription.getKey('p256dh'))));
      const deviceToken = subscription.endpoint
 
      let data = {
        userId: userId,
        deviceToken: deviceToken,
        auth: keyAuth,
        p256dh: keyP256dh
      }
      const response = await axios.post(`${baseURL}/subscribe`, data, config);
      return response.data;
    } catch (error) {
      console.error('Error subscribing to notifications:', error);
      throw error;
    }
  }
};

export const markAllNotificationsAsRead = async (userId) => {
  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
  const response = await axios.get(`${baseURL}/mark-all-read`, config);
  return response.data;
  } catch (error) {
    console.error('Error mark as read notifications:', error);
    throw error;
  }
}