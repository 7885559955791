import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory2';
import HomeIcon from '@mui/icons-material/Home';
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export const Sidebar = ({ open, handleDrawerClose }) => {
  const navigate = useNavigate();

  const items = [
    { text: 'Inicio', icon: <HomeIcon />, path: '/' },
    { text: 'Escanear', icon: <CameraAltIcon />, path: '/product-scan' },
    { text: 'Gestión de Inventario', icon: <InventoryIcon />, path: '/inventory/0' },
  ];

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
    >
      <div style={{textAlign: 'right', paddingTop: '20px', paddingRight: '20px'}}>
        <IconButton onClick={handleDrawerClose}>
          <ClearIcon />
        </IconButton>
      </div>
      <List>
        {items.map((item, index) => (
          <ListItem button key={index} onClick={() => {
            handleDrawerClose();
            navigate(item.path);
          }}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};