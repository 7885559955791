import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../api/auth';
import { loginStart, loginSuccess, loginFailure } from '../store/authSlice';
import { TextField, Button, Typography, Grid, FormControlLabel, Checkbox, Paper, CssBaseline, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { subscribeToNotifications } from '../api/notifications';
import loginBackground from '../assets/images/loginBackground.png';
import logo from '../assets/images/logo_rojo_transparente.png';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  marginBottom: '16px',
});

const CssLink = styled(Link)({
  textDecoration: 'underline',
  color: 'black',
  fontSize: '14px',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
    color: 'darkblue',
  },
  marginBottom: '16px',
});

const Root = styled(Grid)({
  height: "100vh",
});

const Image = styled(Grid)({
  backgroundImage: `url(${loginBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundColor: (props) => props.theme.palette.type === "dark" ? props.theme.palette.grey[900] : props.theme.palette.grey[50],
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const PaperContainer = styled(Paper)({
  margin: '64px 32px',
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
});

const Logo = styled('img')({
  width: '300px',
});

const TextLogin = styled(Typography)({
  marginTop: (props) => props.theme.spacing(1),
  marginBottom: '20px !important',
  height: 30,
  fontSize: 22,
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.36,
  letterSpacing: 1.38,
  color: "#4a4a4a",
});

const Form = styled('form')({
  width: "100%",
});

const SubmitButton = styled(Button)({
  textTransform: "none",
  borderRadius: 15,
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.36,
  letterSpacing: 1.38,
  margin: "15px auto",
  padding: "10px",
  backgroundColor: "#e46450",
  width: '80%',
  maxWidth: '300px',
  "&:hover": {
    backgroundColor: "red",
  },
});

const SignupButton = styled(Button)({
  color: "#3f51b5",
  border: "1px solid rgba(63, 81, 181, 0.5)",
  textTransform: "none",
  borderRadius: 15,
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.36,
  letterSpacing: 1.38,
  margin: "15px auto",
  padding: "10px",
  width: '80%',
  maxWidth: '300px',
});

const ContentContainer = styled('div')({
  margin: '64px 32px',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async (event) => {
    event.preventDefault();
    dispatch(loginStart());
    try {
      const user = await login(email, password);
      localStorage.setItem('token', user.response.token);
      dispatch(loginSuccess(user.response.user));
      subscribeToNotifications(user.response.user.id);

      navigate('/');
    } catch (error) {
      localStorage.clear();
      console.error("Login error:", error);
      dispatch(loginFailure(error.message));
    }
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Root container component="main">
      <CssBaseline />
      <Image item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={8} md={5} component={PaperContainer} elevation={6} square>
        <ContentContainer>
          <Logo src={logo} alt="Logo" />
          <TextLogin component="h1" variant="h5">
            ¡Te damos la bienvenida!
          </TextLogin>
          <Form onSubmit={handleLogin}>
            <CssTextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <CssTextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordChange}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
            >
              Ingresar a mi tienda
            </SubmitButton>
            <Grid container justifyContent="center">
              <Grid item>
                <CssLink to="/forgot-password" variant="body2">
                  Olvidé mi contraseña
                </CssLink>
              </Grid>
            </Grid>
          </Form>
          <Typography align="center" sx={{ my: 2 }}>¿Todavía no usas Market by Mayah?</Typography>
          <SignupButton
            variant="outlined"
            color="primary"
            component={Link}
            to="/signup"
          >
            Crear cuenta
          </SignupButton>
        </ContentContainer>
      </Grid>
    </Root>
  );
};
