import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL + '/products';
const baseURLNotif = process.env.REACT_APP_API_BASE_URL + '/notifications';

export const getTops = async () => {
  let token = localStorage.getItem('token');
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };
    
    const response = await axios.get(`${baseURL}/tops`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNotifications = async () => {
  let token = localStorage.getItem('token');
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    };
    
    const response = await axios.get(`${baseURLNotif}/last`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};