import axios from 'axios';

// const baseURL = process.env.API_BASE_URL;
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const login = async (email, password) => {
  try {
    console.log("API: ",);
    const response = await axios.post(`${baseURL}/auth/login`, { email, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signup = async (name, storeName, email, phone, password) => {
  try {
    const response = await axios.post(`${baseURL}/auth/signup`, { name, storeName, email, phone, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};
