import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Card, CardContent, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { productTraceability } from '../api/products';

const FormContainer = styled(Container)({
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
});

const FormTitle = styled(Typography)({
  marginBottom: '16px',
  fontSize: '24px',
  fontWeight: 'bold',
});

const SearchButton = styled(Button)({
  marginTop: '24px',
  marginBottom: '16px',
});

const ReportCard = styled(Card)({
  marginTop: '24px',
});

const ReportCardContent = styled(CardContent)({
  backgroundColor: '#ffffff',
});

export const Traceability = () => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [report, setReport] = useState(null);

  const handleSearch = async () => {
    // Simular la llamada API
    let responseData = await productTraceability(selectedProduct, startDate, endDate);
    console.log(responseData);
    setReport(responseData);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <FormContainer component="main" maxWidth="md">
      <Typography variant="body1" sx={{ mb: 2 }}>
        Indica el producto y el rango de fechas para conocer todos sus movimientos.
      </Typography>
      <Box component="form" noValidate>
        <TextField
          fullWidth
          label="SKU del Producto"
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
          margin="normal"
          sx={{ backgroundColor: '#f7f7f7', marginBottom: '20px' }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Fecha Inicio"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ backgroundColor: '#f7f7f7' }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Fecha Fin"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              sx={{ backgroundColor: '#f7f7f7' }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <SearchButton
          type="button"
          fullWidth
          variant="contained"
          onClick={handleSearch}
        >
          Buscar
        </SearchButton>
      </Box>
      {report ? (
          <ReportCard variant="outlined">
            <ReportCardContent>
              <Typography variant="h6">Trazabilidad de: {report.name}, {report.brand}</Typography>
            {report.expirations.map((register, index) => (
              <div key={index}>
                <Typography>
                  Unidades a la fecha: {register.stock}
                </Typography>
                <Typography>
                  Fecha actualizacion: {formatDate(register.updated_date)}
                </Typography>
                <Typography>
                  actualizo: {register.updated_by}
                </Typography>
              </div>
            ))}
            </ReportCardContent>
          </ReportCard>
      )
    : <ReportCard>
      <Typography variant="h6">Resultado de Trazabilidad</Typography>
      <Typography>
        No se encontraron registros de trazabilidad en las fechas seleccionadas.
      </Typography>
    </ReportCard>}
    </FormContainer>
  );
};
