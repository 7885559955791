import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Stock } from '../components/Stock';
import { Expirations } from '../components/Expirations';
import { Traceability } from '../components/Traceability';

const InventoryContainer = styled(Box)({
  backgroundColor: '#f7f7f7',
  minHeight: '100vh',
  padding: '20px',
});

const InventoryTitle = styled(Typography)({
  marginBottom: '20px',
  fontSize: '24px',
  fontWeight: 'bold',
});

const CustomTabs = styled(Tabs)({
  marginBottom: '20px',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'center', 
  width: '100%'
});

const CustomTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 'bold',
  minWidth: 'auto',
  flex: 1,
  maxWidth: 'none',
  '&:not(:last-child)': {
    marginRight: '10px',
  },
});

const TabContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

export const InventoryManagement = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [value, setValue] = useState(parseInt(routeParams.index, 10) || 0); // Default to 0 or from URL
  const [title, setTitle] = useState("");

  const titles = [
    "Gestión Integral del Stock de Productos",
    "Control de Fechas de Vencimiento",
    "Análisis de Trazabilidad de Productos"
  ];

  useEffect(() => {
    // Update the state only if the URL param index is different
    const indexFromUrl = parseInt(routeParams.index, 10);
    if (indexFromUrl !== value) {
      setValue(indexFromUrl);
    }
  }, [routeParams.index]);

  useEffect(() => {
    setTitle(titles[value]);

    // Navigate only if the current value doesn't match the URL parameter
    if (parseInt(routeParams.index, 10) !== value) {
      navigate(`/inventory/${value}`);
    }
  }, [value, navigate, routeParams.index]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <InventoryContainer>
      <InventoryTitle variant="h6" gutterBottom>
        {title}
      </InventoryTitle>
      <TabContainer>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="tabs de inventario"
          variant={isDesktop ? 'fullWidth' : 'scrollable'}
          scrollButtons="auto"
          centered={isDesktop}
        >
          <CustomTab label="Stock" />
          <CustomTab label="Vencimientos" />
          <CustomTab label="Trazabilidad" />
        </CustomTabs>
      </TabContainer>
      {value === 0 && <Stock />}
      {value === 1 && <Expirations />}
      {value === 2 && <Traceability />}
    </InventoryContainer>
  );
};
