import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginStart, loginSuccess, loginFailure } from '../store/authSlice';
import { signup } from '../api/auth';
import { TextField, Button, Typography, Container, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

const CssLink = styled(Link)({
  textDecoration: 'underline', // Elimina la subrayado por defecto
  color: 'black', // Define el color del texto
  fontSize: '16px', // Tamaño de la fuente
  fontWeight: 'bold', // Peso de la fuente
  '&:hover': {
    textDecoration: 'underline', // Subraya el enlace al pasar el ratón por encima
    color: 'darkblue' // Cambia el color al pasar el ratón por encima
  }
});

export const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [storeName, setStoreName] = useState('')
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSignup = async (event) => {
    event.preventDefault();
    if (validatePassword(password)) {
      if (password === confirmPassword) {
        dispatch(loginStart());
        try {
          const user = await signup(name, storeName, email, phone, password);
          localStorage.setItem('token', user.response.token);
          dispatch(loginSuccess(user.response.user));
          navigate('/');
        } catch (error) {
          dispatch(loginFailure(error.message));
        }
      } else {
        setPasswordError('Las contraseñas no coinciden');
      }
    } else {
      setPasswordError('La contraseña debe tener al menos 8 caracteres, al menos dos mayúsculas y un símbolo');
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z].*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])(?=.*[a-z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography component="h1" variant="h5" sx={{ mt: 8, mb: 2 }}>
          Crear cuenta
        </Typography>
        <form onSubmit={handleSignup} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                id="storeName"
                label="Nombre de tu empresa"
                name="storeName"
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Tu nombre"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Teléfono"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError !== ''}
                helperText={passwordError}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirmar Contraseña"
                type={showPassword ? 'text' : 'password'}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox checked={showPassword} onChange={handleShowPasswordChange} />}
                label="Mostrar contraseña"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Crear cuenta
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <CssLink to='/login' variant="body2">
                ¿Ya tienes una cuenta? Iniciar sesión
              </CssLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};