import axios from 'axios';

// const baseURL = process.env.API_BASE_URL;
const baseURL = process.env.REACT_APP_API_BASE_URL + '/products';

export const listProducts = async (orderBy) => {
  let token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
    const response = await axios.get(`${baseURL}/list/${orderBy}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProduct = async (productData) => {
  let token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    }
  };
  try { 
    const response = await axios.post(`${baseURL}/newProduct`, productData, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handlefilterProductsExpirations = async (searchText, orderBy) => {
  let token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
    const response = await axios.get(`${baseURL}/filter/${searchText}/${orderBy}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleSearchProductStock = async (searchText, orderBy) => {
  let token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
    const response = await axios.get(`${baseURL}/search/${searchText}/${orderBy}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productTraceability = async (SKU, startDate, endDate) => {
  let token = localStorage.getItem('token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
    const response = await axios.get(`${baseURL}/traceability/${SKU}/${startDate}/${endDate}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProductStock = async (productID, stock, expiration) => {
  let token = localStorage.getItem('token');
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
    const response = await axios.put(`${baseURL}/update-stock/${productID}`, {stock, expiration}, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setProductComertialAction = async (productID, expiration, comercial_action) => {
  let token = localStorage.getItem('token');
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  try {
    const response = await axios.post(`${baseURL}/set-comertial-action/${productID}`, {comercial_action, expiration}, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};