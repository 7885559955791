import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import notificationsReducer from './notificationSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'notifications']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);