import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HomeCard } from '../components/cards/HomeCard';
import { getTops, getNotifications } from '../api/home';
import { SkeletonHome } from '../components/cards/SkeletonHome';

import ic_glass_bag from '../assets/icons/ic_glass_bag.png';
import ic_glass_buy from '../assets/icons/ic_glass_buy.png';
import ic_glass_message from '../assets/icons/ic_glass_message.png';

import {
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
} from '../store/notificationSlice';

const HomeContainer = styled(Container)({
  backgroundColor: '#f7f7f7',
  padding: '20px',
  minHeight: '100vh',
});

const WelcomeMessage = styled(Typography)({
  marginBottom: '20px',
  fontSize: '24px',
  fontWeight: 'bold',
});

const CardsContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '20px',
});

export const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [lowStockProducts, setLowStockProducts] = useState(0);
  const [expiringProducts, setExpiringProducts] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getProductsTops = async () => {
    setIsLoading(true);
    try {
      let topProducts = await getTops();
      setLowStockProducts(topProducts.stock);
      setExpiringProducts(topProducts.expirations);
      setTotalProducts(topProducts.products);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllNotifications = async () => {
    dispatch(fetchNotificationsStart());
    try {
      let allNotifications = await getNotifications();
      dispatch(fetchNotificationsSuccess(allNotifications.response));
    } catch (err) {
      dispatch(fetchNotificationsFailure(err.toString()));
    }
  };

  useEffect(() => {
    getProductsTops();
    getAllNotifications();
  }, []);

  return (
    <HomeContainer>
      {user && user.name && (
        <WelcomeMessage>
          Hola. Bienvenido {user.name} 👋
        </WelcomeMessage>
      )}
      {isLoading ? (
        <>
          <SkeletonHome />
          <SkeletonHome />
          <SkeletonHome />
        </>
      ) : (
        <CardsContainer container>
          <Grid item xs={12} sm={6} md={3}>
            <HomeCard
              title="Productos con stock bajo"
              total={lowStockProducts}
              color="success"
              icon={<img alt="icon" src={ic_glass_buy} />}
              redirectTo="/inventory/0"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <HomeCard
              title="Próximos vencimientos"
              total={expiringProducts}
              color="info"
              icon={<img alt="icon" src={ic_glass_message} />}
              redirectTo="/inventory/1"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <HomeCard
              title="Total de productos escaneados"
              total={totalProducts}
              color="info"
              icon={<img alt="icon" src={ic_glass_bag} />}
              redirectTo="/inventory/2"
            />
          </Grid>
        </CardsContainer>
      )}
    </HomeContainer>
  );
};
