import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/authSlice';

import { AppBar, Toolbar, IconButton, useMediaQuery, useTheme, Avatar, Menu, MenuItem, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Sidebar } from './SideBar';

import { BottomNavigationComponent } from './BottomNavigationComponent';
// Import logo_blanco_transparente from assets/images
import logo_blanco_transparente from '../assets/images/logo_blanco_transparente.svg';
import { NotificationsPopover } from './NotificationsPopOver';

export const Layout = ({ children }) => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogoutClick = () => {
    dispatch(logout());
    //  clear all localStorage data
    localStorage.clear();
    
    handleDrawerClose()
    // Redirige al usuario a la página de inicio de sesión o a la página de inicio, dependiendo de tus necesidades
    // refresh the page
    window.location.reload();
    //navigate('/login'); // Importa navigate desde react-router-dom si aún no lo has hecho
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    console.log('Settings clicked');
    handleMenuClose()
  };

  return (
    <div style={{ display: 'flex' }}>
      <AppBar position="fixed" style={{borderRadius: '0 0 35px 0'}}>
        <Toolbar>
          {isDesktop && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img src={logo_blanco_transparente} style={{ height: 'auto', width: 110, paddingRight: 10 }} alt='Logo Market by Mayah'/>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
            <NotificationsPopover />
            <Avatar
              onClick={handleMenuOpen}
              sx={{ cursor: 'pointer', marginLeft: 'auto', backgroundColor: theme.palette.primary.main, w: 40, h: 40 }}
            >
              <AccountCircleIcon />
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {user && user.name ? 
                <MenuItem>
                  {user.name}
                </MenuItem>
                : null
                }
              <MenuItem onClick={handleSettingsClick}>
                <SettingsIcon />
                Configuraciones
              </MenuItem>
              <MenuItem onClick={handleSettingsClick}>
                <ContactSupportIcon />
                Ayuda
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>
                <ExitToAppIcon />
                Cerrar sesión
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>
      {isDesktop && <Sidebar open={drawerOpen} handleDrawerClose={handleDrawerClose} />}
      <main style={{ flexGrow: 1, marginLeft: isDesktop && drawerOpen ? 240 : 0 }}>
        <Toolbar /> {/* Asegura que el contenido no se solape con el AppBar */}
        {children}
      </main>
      {!isDesktop && <BottomNavigationComponent />}
    </div>
  );
}; 
