import React from 'react'
import { Card, CardContent, Box, Skeleton } from '@mui/material'

export const SkeletonHome = () => {
  return (
    <Card sx={{ display: 'flex', justifyContent: "space-between", mt: 2 }}>
    <Skeleton variant="rectangular" width={90} height={100} sx={{ alignSelf: 'center' }} />
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Skeleton variant="rectangular" width="60%" height={20} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="90%" />
      </CardContent>
    </Box>
  </Card>
  )
}
