import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  IconButton
} from "@mui/material";
import { SkeletonProducts } from "./cards/SkeletonProducts";
import SortIcon from '@mui/icons-material/Sort';
import SearchIcon from '@mui/icons-material/Search';

import { InventoryStock } from "./cards/InventoryStock";
import {
  handleSearchProductStock,
  handlefilterProductsExpirations,
  listProducts,
} from "../api/products";
import { CustomizedAlert } from "../components/CustomizedAlert";

export const Expirations = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsBackUp, setProductsBackUp] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const getDaysToExpiration = (expirationDate) => {
    const today = new Date();
    const expiration = new Date(expirationDate);
    let days = Math.ceil(
      (expiration.getTime() - today.getTime()) / (1000 * 3600 * 24)
    );
    return days;
  };

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const products = await listProducts("expiration_date");
      products.map((product) => {
        // console.log("PRODUCT: ", product);
        // let quantity = 0;
        let daysToExpiration = 0;
        // product.latest_expirations.forEach((expiration) => {
        //   quantity += expiration.stock;
        //   daysToExpiration = getDaysToExpiration(expiration.expiration_date);
        // });
        daysToExpiration = getDaysToExpiration(product.expiration_date);
        
          product.daysToExpiration = daysToExpiration
          product.quantity = product.stock
          product.creation_date = '27/06/2024'
          
          product.name = product.product.name
          product.description = product.product.description
          product.brand = product.product.brand
          product.category = product.product.category
          product.stores_id = product.product.stores_i
        
        return product
      });
      setProducts(products);
      setProductsBackUp(products);
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR: ", error);
      setProducts([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(); // eslint-disable-next-line
  }, []);

  const handleSearchProduct = async (searchtext) => {
    try {
      let findProducts = await handleSearchProductStock(
        searchtext,
        "expiration_date"
      );
      findProducts.map((product) => {
        let quantity = 0;
        let daysToExpiration = 0;
        product.latest_expirations.forEach((expiration) => {
          quantity += expiration.stock;
          daysToExpiration = getDaysToExpiration(expiration.expiration_date);
        });
        product.daysToExpiration = daysToExpiration;
        product.quantity = quantity;
        return product;
      });
      setProducts(findProducts);
    } catch (error) {
      setProducts(productsBackUp);
      setIsAlertOpen(true);
      setAlertMessage("No se encontraron productos con esa búsqueda");
      setAlertSeverity("error");
      console.log(error);
    }
  };

  const handleFilterProducts = async (searchtext) => {
    if (searchtext === "-1") {
      setProducts(productsBackUp);
      return;
    }
    try {
      let findProducts = await handlefilterProductsExpirations(
        searchtext,
        "expiration_date"
      );
      findProducts.map((product) => {
        let quantity = 0;
        let daysToExpiration = 0;
        product.latest_expirations.forEach((expiration) => {
          quantity += expiration.stock;
          daysToExpiration = getDaysToExpiration(expiration.expiration_date);
        });
        product.daysToExpiration = daysToExpiration;
        product.quantity = quantity;
        return product;
      });
      setProducts(findProducts);
    } catch (error) {
      setProducts(productsBackUp);
      setIsAlertOpen(true);
      setAlertMessage("No se encontraron productos con ese filtro");
      setAlertSeverity("error");
      console.log(error);
    }
  };

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <Box padding={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!filterOpen ? (
          <>
            <TextField
              fullWidth
              label="Buscar productos o categorías"
              id="fullWidth"
              sx={{
                backgroundColor: "#FFF",
                flex: 7,
                transition: 'flex 0.3s ease',
                marginRight: 2
              }}
              onChange={(e) => handleSearchProduct(e.target.value)}

            />
            <Button onClick={toggleFilter} variant="contained">
              <SortIcon />
            </Button>
          </>
        ) : (
            <Button onClick={toggleFilter} variant="contained">
              <SearchIcon />
            </Button>
        )}
        <Box
          display={filterOpen ? 'block' : 'none'}
          sx={{
            flex: filterOpen ? 3 : 0,
            transition: 'flex 0.3s ease',
            marginLeft: 2
          }}
        >
          {/* <InputLabel id="expiration-filter">Filtrar por vencimientos</InputLabel> */}
          <Select
            fullWidth
            value={selectedFilter}
            // labelId="expiration-filter"
            id="demo-simple-select"
            // label="Filtrar por vencimientos"
            onChange={(e) => {
              setSelectedFilter(e.target.value);
              handleFilterProducts(e.target.value);
            }}
          >
            <MenuItem value={-1}>Todos</MenuItem>
            <MenuItem value={0}>Hoy</MenuItem>
            <MenuItem value={1}>Próximos 5 días</MenuItem>
            <MenuItem value={2}>Próximos 10 días</MenuItem>
            <MenuItem value={3}>Próximos 20 días</MenuItem>
          </Select>
        </Box>
      </Box>
      {isLoading ? (
        <>
          <SkeletonProducts />
          <SkeletonProducts />
          <SkeletonProducts />
        </>
      ) : (
        <>
          {products &&
            products.length > 0 &&
            products.map((product) => (
              <InventoryStock
                key={product.id}
                productData={product}
                actionCallBack={()=>{console.log("Soy una callback")}}
              />
            ))}
          {products && products.length === 0 && (
            <>
              <Typography sx={{ textAlign: "center", mt: 4 }}>
                Aún no cargaste productos
              </Typography>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 4 }}
                onClick={() => {
                  navigate("/product-scan");
                }}
              >
                Cargar productos
              </Button>
            </>
          )}
        </>
      )}

      <CustomizedAlert
        message={alertMessage}
        severity={alertSeverity}
        openHandler={isAlertOpen}
      />
    </Box>
  );
};